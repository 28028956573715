
import AddModal from "../../components/list/AddMoreModal.vue";
import PageHeader from "../../components/list/PageHeader.vue";
import { defineComponent, onMounted, reactive, toRefs,ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import DashboardService from "../../services/dashboard";
import Loader from '../../components/comp/Loader.vue';



export default defineComponent({
  components: { PageHeader, AddModal,Loader },
  name: "signin",
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
   const packageBarElement = ref<HTMLElement | null>(null);
    const state = reactive({
      isLoading:true,
      viewData:{},
      packagebar:0,
      qurbanibar:0,
      productbar:0,
      currentDate: formatDate( new Date().toLocaleString()),

      
    });
    function formatDate(dateString:any) {
      const [month, day, year] = dateString.split('/');
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthName = monthNames[parseInt(month) - 1];
      return `${day} ${monthName} ${year}`;
    }

    const dashboardView=()=>{
            new DashboardService().DashboardView().then((response:any)=>{
                if(response !== undefined){
                    state.viewData = response.data
                    state.packagebar = response.data.packagebar
                    state.qurbanibar = response.data.qurbanibar
                    state.productbar = response.data.productbar
                }
                console.log("packagebar",state.packagebar)
                
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });

        }
    

    onMounted(() => {
        dashboardView();
        
      setTimeout(() => {
        state.currentDate,
              state.isLoading = false  
            }, 1000);
    });
    return {
      formatDate,
      dashboardView,
      packageBarElement,
      ...toRefs(state),
    };
  },
});
