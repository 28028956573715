import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start space-x-1.5" }
const _hoisted_2 = { class: "capitalize text-left" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_4 = {
  width: "10",
  height: "10",
  viewBox: "0 0 20 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_5 = {
  width: "10",
  height: "10",
  viewBox: "0 0 20 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("th", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sortIconClick',_ctx.name))),
    class: "text-sm dark:text-secondary font-medium text-black100 cursor-pointer px-3 sm:px-6 py-4"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
      (_ctx.sortoption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(), _createElementBlock("svg", _hoisted_4, [
              _createElementVNode("path", {
                d: "M12 9.62545V24H8V9.62545H0L10 0L20 9.62545H12Z",
                fill: "",
                class: _normalizeClass(_ctx.sortValue == 1 && _ctx.header == _ctx.name  ? 'dark:fill-green-400 fill-green-400' : 'dark:fill-secondary fill-black100')
              }, null, 2)
            ])),
            (_openBlock(), _createElementBlock("svg", _hoisted_5, [
              _createElementVNode("path", {
                d: "M12 14.3746V0H8V14.3746H0L10 24L20 14.3746H12Z",
                fill: "",
                class: _normalizeClass(_ctx.sortValue == 2 && _ctx.header == _ctx.name ? 'dark:fill-green-400 fill-green-400' : 'dark:fill-secondary fill-black100')
              }, null, 2)
            ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}