
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted, onBeforeUnmount } from 'vue'
import actionDropdown from '../../composable/actionDropdown';
import ActionModalVue from '../../../components/list/ActionModal.vue';
import AddModal from '../../../components/list/AddMoreModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import TableCompo from '../../../components/list/TableCompo.vue';
import TablePaggination from '../../../components/list/TablePaggination.vue';
import TableTitleColumn from '../../../components/list/TableTitleColumn.vue';
import TableDataComp from '../../../components/list/TableDataComp.vue';
import FilterModal from '../../../components/list/FilterModal.vue';
import { useStore } from 'vuex';
import { UserListFilterRequest, UpdateUserStatus } from '../../../types/user';
import UserService from "../../../services/user";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { toastrMsg } from "../../../utils/toastr";
import { routeGroupCheck } from "../../../utils/route-utils"
import Loader from '../../../components/comp/Loader.vue';

let listFilterData : UserListFilterRequest={
    searchterm: "",
    startdate: "",
    enddate: "",
    phonenumber: "",
    roleid: "",
    sortbycolumn: "",
    sortby: "",
    status: "",
    cursor: "",
    limit: 10,
    cursortype:'CURSOR_TYPE_UNKNOWN'
}

export default defineComponent({
    components: {FilterModal,  ActionModalVue, PageHeader, AddModal, TableCompo, TableTitleColumn, TablePaggination, TableDataComp, Loader },
    name: 'UserListComp',
    setup(){
        const { actionModalID, actionButton, positionEditDrop, toggleActionDropdown } = actionDropdown();
        const store = useStore();
        const state = reactive(
            {
                isLoading:true,
                isFilterOpen:false,
                tableData:<any>[],
                filterForm:listFilterData,
                sortIconData : 0,
                activeHeader : '',
                prevElm : '',
                userStatus: false,
                NextCursor:'',
                PreviousCursor:'',
                ListLength:0,
                roleList:{}
            }
        );

        let resCol = ref(8)

        function controlColspan(){
            if(window.matchMedia("(max-width: 768px)").matches){
                resCol.value = 5
            }
            else if(window.matchMedia("(max-width: 1280px)").matches){
                resCol.value = 7
            }else{
                resCol.value = 8
            }
        }
        function newRowCreate(e:any,data:any){
            e.target.closest('.expandButton').classList.toggle('isExpand')
            e.target.closest('.expandButton').children[0].classList.toggle('hidden')
            e.target.closest('.expandButton').children[1].classList.toggle('hidden')

            let newRow:any;
            newRow = document.createElement('tr')
            newRow.className = 'child-row bg-secondary lg:hidden'

            let tableClosest = e.target.closest('td')

            if(e.target.closest('.expandButton').classList.contains('isExpand')){

                newRow.innerHTML = `<td colspan='${resCol.value}' class="py-3 px-6">
                        <ul class="flex flex-wrap">
                            <li class="pr-4 py-1 table-cell">
                                <span class="capitalize font-semibold">Email:</span><span class="ml-2">${data.Email}</span>
                            </li>
                            <li class="pr-4 py-1 table-cell">
                                <span class="capitalize font-semibold">Last Name:</span><span class="ml-2">${data.LastName}</span>
                            </li> 
                            <li class="pr-4 py-1 table-cell">
                                <span class="capitalize font-semibold">Phone Number:</span><span class="ml-2">${data.PhoneNumber}</span>
                            </li>
                        </ul>
                    </td>`

                tableClosest.parentElement.insertAdjacentElement("afterEnd", newRow)

            }else{
                e.target.closest('tr').nextElementSibling.remove()
            }
        }
        function removeNewRow(){
            let allChildRow = document.querySelectorAll('.child-row')
            let allExpandButton = document.querySelectorAll('.expandButton')
            allChildRow.forEach(el=>{
                el.remove()
            })
            allExpandButton.forEach(el=>{
                el.classList.remove('isExpand')
                el.children[0].classList.remove('hidden')
                el.children[1].classList.add('hidden')
            })
        }
        onUnmounted(()=>{
            window.removeEventListener('resize', controlColspan);
        })
        const toggleModal=()=>{
            actionModalID.value='';
        };
        const openFilter=()=>{
            state.isFilterOpen = true;
        }

        const closeFilter=()=>{
            state.isFilterOpen = false;
        }

        const setTableData = ()=>{
            new UserService().listOfUser(state.filterForm).then((response:any)=>{
                if(response !== undefined && response.Data!==undefined){
                    state.tableData=response.Data.User;
                    state.NextCursor=response.Data.NextCursor;
                    state.PreviousCursor=response.Data.PreviousCursor;
                    state.ListLength=response.Data.TotalPages;
                    
                }
            }).catch((error)=>{});
        }
        const resetFilterForm=()=>{
            state.filterForm.status='';
            state.filterForm.roleid='';
            let data={
                status:'',
                roleid:'',
            }
            store.dispatch('setUserFilter', data);
            setTableData();
        }
        const updateSearchValue=(newValue:any)=>{
            state.filterForm.searchterm=newValue;
        }

        const setNewTableData =(newValue:any)=>{
            state.filterForm.searchterm=newValue;
            state.filterForm.cursortype='CURSOR_TYPE_UNKNOWN';
            state.filterForm.cursor='';
            setTableData();
        }

        const getListBySearch=(ev:any)=>{
            state.filterForm.searchterm = ev.parentElement.children[0].value
            store.dispatch('setUserSrcTerm', state.filterForm.searchterm);
            setTableData();
        }
        const resetSearchTerm=()=>{
            state.filterForm.searchterm='';
            store.dispatch('setUserSrcTerm', '');
            setTableData();
        }
        const getListByFiltering=()=>{
            let data={
                status:state.filterForm.status,
                roleid:state.filterForm.roleid,
            }
            store.dispatch('setUserFilter', data);
            setTableData();
            state.isFilterOpen = false;
        }

        const updateLimit=(newLimit:any)=>{
            state.filterForm.cursor='';
            state.filterForm.limit=newLimit;
            state.filterForm.cursortype='CURSOR_TYPE_UNKNOWN';
            state.NextCursor='';
            state.PreviousCursor='';
            setTableData();
        }

        const getPrevData=()=>{
            state.filterForm.cursor=state.PreviousCursor;
            state.filterForm.cursortype='PREVIOUS';
            if(state.filterForm.cursor!==''){
                setTableData();
            }
        }

        const getNextData=()=>{
            state.filterForm.cursor=state.NextCursor;
            state.filterForm.cursortype='NEXT';
            if(state.filterForm.cursor!==''){
                setTableData();
            }
        }

        const sortIconClickFun=(e:any,sort:string)=>{
           removeNewRow()
           state.activeHeader = e
            if(state.prevElm == e || state.prevElm == ''){
                state.sortIconData ++
                if(state.sortIconData == 3){
                    state.sortIconData = 0
                }
            }else{
                 state.sortIconData = 1
            }

            if(state.sortIconData==1){
                state.filterForm.sortbycolumn=sort;
                state.filterForm.sortby='DESC';
            }
            else if(state.sortIconData==2){
                state.filterForm.sortbycolumn=sort;
                state.filterForm.sortby='ASC';
            }
            else {
                state.filterForm.sortby='';
                state.filterForm.sortbycolumn='';
            }
    
            state.prevElm = e;
            state.filterForm.cursortype = 'CURSOR_TYPE_UNKNOWN';
            state.filterForm.cursor = '';
            setTableData();
        }
        const changeStatus=(status:any, id:string)=>{
            if(status =='ACTIVE') status = 2;
            else status = 1;
            let payload: UpdateUserStatus ={
                id : id,
                status : status,
            }
            new UserService().updateUserStatus(payload).then((response:any)=>{
                if(response !== undefined && response.Data!==undefined){
                    toast.success("Status has been changed successfully.");
                    setTableData();
                }
            }).catch((error)=>{});   
        }

        onBeforeUnmount(()=>{
            let routeData = routeGroupCheck();
            if (routeData.reset == "TRUE") {
                resetFilterForm()
                resetSearchTerm()
            }
        })

        onMounted(()=>{
            state.filterForm.status = store.state.usermanagement.filter.status == undefined? '' : store.state.usermanagement.filter.status;
            state.filterForm.searchterm = store.state.usermanagement.filter.searchterm;
            state.filterForm.roleid = store.state.usermanagement.filter.roleid == undefined? '' : store.state.usermanagement.filter.roleid;
            resetSearchTerm()
            setTableData();
            positionEditDrop();
            window.addEventListener('resize', controlColspan);

            if(localStorage.getItem('fromRoute') && localStorage.getItem('fromRoute')=='create'){
                toastrMsg('User created successfully.');
            }
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })

        return {
            ...toRefs(state),sortIconClickFun,setTableData, updateSearchValue, newRowCreate, removeNewRow,positionEditDrop,
            resetFilterForm, openFilter, closeFilter, actionModalID, toggleActionDropdown, toggleModal, actionButton,
            changeStatus, updateLimit, getPrevData, getNextData, getListBySearch, resetSearchTerm, getListByFiltering,setNewTableData
            
        }

    }
  
})
