
import { defineComponent, reactive, toRefs, onMounted, } from 'vue'
import ProfileOptions from './ProfileOptions.vue';


export default defineComponent({
  components: { ProfileOptions },
    name:'Profile',

    setup(){
    }
})
