
import { defineComponent } from 'vue'
export default defineComponent({
    name:'ListHeader',
    props: {
        title:{
           type: String,
           required: true
        },
        filter:{
           type: Boolean,
           required: false
        },
        search:{
           type: Boolean,
           required: false
        },
        searchInput:{
            type: String,
            required: false
        }
    },
    emits:['filter', 'search', 'resetSearch','enter','update', ]
})
