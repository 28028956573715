import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-center bg bg-gray-400" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "w-full md:w-1/5 px-4 py-4",
          key: index
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "w-full h-48",
              src: item.url,
              alt: ""
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(item.name), 1),
          _createElementVNode("a", {
            onClick: ($event: any) => (_ctx.RemoveImage(item.id)),
            href: "javascript:;",
            class: "text-center inline-block w-full py-1 text-xs bg-red-600 text-white"
          }, "remove", 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}