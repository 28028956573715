
import { defineComponent, reactive, toRefs, onMounted, } from 'vue'
import store from "../../store";
import { useRouter } from 'vue-router';
import ProfileOptions from './ProfileOptions.vue';
import ProfileService from "../../services/profile";
export default defineComponent({
  components: { ProfileOptions },
    name:'Profile',

    setup(){
       const state = reactive({
        userInfo : <any> {},
        userRole : '',
       })
        const getUserData = ()=>{
            new ProfileService().GetProfile().then((response:any)=>{
                if(response !== undefined && response.Data!==undefined){
                    state.userInfo = response.Data.GetProfileByID
                }
            }).catch((error)=>{
            });
        } 
        const getUserRole = ()=>{
            new ProfileService().GetProfileRole().then((response:any)=>{
                if(response !== undefined){
                   state.userRole = response.Name 
                }
            }).catch((error)=>{
               
            });
        }

        onMounted(()=>{
            getUserData()
            getUserRole()
        })
       return{
        ...toRefs(state),getUserData,getUserRole
       }
    }
})
