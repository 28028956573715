
  import { defineComponent } from 'vue'
  
  export default defineComponent({
      name: '429error',
  
      components: {},
  
      data(){
          return {
           
          };
      },
  
      methods:{
       
      },
  
      computed:{
  
      },
  
      mounted(){
         
      }
    
  })
  