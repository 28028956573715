import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-3 sm:px-6 py-3" }
const _hoisted_2 = { class: "flex items-center justify-start space-x-3 dark:text-secondary text-black100 font-medium" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.cls),
        src: _ctx.src
      }, null, 10, _hoisted_3)
    ])
  ]))
}