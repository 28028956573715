
import AddModal from "../../components/list/AddMoreModal.vue";
import PageHeader from "../../components/list/PageHeader.vue";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import Loader from '../../components/comp/Loader.vue';
import { string } from "mathjs";



export default defineComponent({
  components: { PageHeader, AddModal,Loader },
  name: "signin",
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      isLoading:true,
      
    });
   
    

    onMounted(() => {
      setTimeout(() => {
              state.isLoading = false  
            }, 1000);
    });
    return {
      ...toRefs(state),
    };
  },
});
