
import AddModal from "../../components/list/AddMoreModal.vue";
import PageHeader from "../../components/list/PageHeader.vue";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { CreateUserRequest } from "../../types/user";
import UserService from "../../services/user";
import { useVuelidate } from "@vuelidate/core";
import { useErrorHandler } from "../../views/composable/handleError";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
sameAs,
} from "@vuelidate/validators";

import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import { GetSettingsRequest } from "../../types/settings";
import Loader from '../../components/comp/Loader.vue';

let registerForm: CreateUserRequest = {
  name: "",
  email: "",
  password: "",
  confirmpassword:"",
  mobilenumber: "",
  mobilecode: "",
  photourl: "",
};

export default defineComponent({
  components: { PageHeader, AddModal, VueHcaptcha,Loader },
  name: "signup",
  setup() {
    const { logOut } = useErrorHandler();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      isLoading:true,
      form: registerForm,
      backendErrors: <any>{},
      authModalOpen: false,
      passwordData: {
        newPassEye: false,
        newPassNotEye: true,
        confirmPassEye: false,
        confirmPassNotEye: true,
      },
      isModalOpen: false,
    });
    const isanyDigit = (value: string) => {
      return value.match(/\d/) !== null;
    };
    const isanyUppercase = (value: string) => {
      return value.match(/[A-Z]/) !== null;
    };
    const isanyLowercase = (value: string) => {
      return value.match(/[a-z]/) !== null;
    };
    const isanySpecialCharacter = (value: string) => {
      return value.match(/[^a-zA-Z\d\s]/) !== null;
    };
  
    const comparePassword = (value: string) => {
      return value === state.form.password;
    };
    const toggleModal = () => {
      state.isModalOpen = !state.isModalOpen;
    };

    const createRule = {
      form: {
        name: {
          required1: helpers.withMessage(
            `${t("message.first-name-is-required")}`,
            required
          ),
          required2: helpers.withMessage(
            `${t("message.this-length")}`,
            maxLength(20)
          ),
        },
        email: {
          required1: helpers.withMessage(
            `${t("message.email-is-required")}`,
            required
          ),
          required2: helpers.withMessage(
            `${t("message.insert-valid-email")}`,
            email
          ),
        },
        mobilecode: {
          required1: helpers.withMessage(
            `${t("message.mobilecode-is-required")}`,
            required
          ),
        },
        mobilenumber: {
          required1: helpers.withMessage(
            `${t("message.phone-number-is-required")}`,
            required
          ),
          required2: helpers.withMessage(
            `${t("message.phone-number-maximum")}`,
            maxLength(20)
          ),
          required3: helpers.withMessage(
            `${t("message.phone-number-minimum")}`,
            minLength(5)
          ),
        },
        password: {
          required6: helpers.withMessage(`${t("message.required")}`, required),
          required1: helpers.withMessage(
            `${t("message.password-digit")}`,
            isanyDigit
          ),
          required2: helpers.withMessage(
            `${t("message.password-uppercase")}`,
            isanyUppercase
          ),
          required3: helpers.withMessage(
            `${t("message.password-lowercase")}`,
            isanyLowercase
          ),
          required4: helpers.withMessage(
            `${t("message.password-special-character")}`,
            isanySpecialCharacter
          ),
          required5: helpers.withMessage(
            `${t("message.password-length")}`,
            minLength(6)
          ),
        },
        
        confirmpassword: {
          required1: helpers.withMessage(
            `${t("message.password-is-required")}`,
            required
          ),
          required2: helpers.withMessage(
            `${t("message.password-compare")}`,
            comparePassword
          ),
        },
      },
    };

    const V1$ = useVuelidate(createRule, state);
    const getBackendError = (item: any) => {
      for (const key in state.backendErrors) {
        if (item == key) {
          return state.backendErrors[key];
        }
      }
    };
    const showPass = (e: any) => {
      if (e.parentElement.children[0].name == "Password") {
        e.parentElement.children[0].type = "text";
        state.passwordData.newPassEye = true;
        state.passwordData.newPassNotEye = false;
      }
      if (e.parentElement.children[0].name == "ConformPassword") {
        e.parentElement.children[0].type = "text";
        state.passwordData.confirmPassEye = true;
        state.passwordData.confirmPassNotEye = false;
      }
    };
    const hidepass = (e: any) => {
      if (e.parentElement.children[0].name == "Password") {
        e.parentElement.children[0].type = "password";
        state.passwordData.newPassEye = false;
        state.passwordData.newPassNotEye = true;
      }
      if (e.parentElement.children[0].name == "ConformPassword") {
        e.parentElement.children[0].type = "password";
        state.passwordData.confirmPassEye = false;
        state.passwordData.confirmPassNotEye = true;
      }
    };

    const createUserRegister = () => {
      V1$?.value.$touch();
      if (V1$?.value.$invalid) {
        return;
      } else {
        new UserService()
          .CreateUser(state.form)
          .then((response: any) => {
            if (response !== undefined && response.CreateUserData !== undefined) {
              V1$.value.$reset();
              localStorage.setItem("userEmail", state.form.email);
              state.form.name = "";
              state.form.email = "";
              state.form.mobilecode = "";
              state.form.mobilenumber = "";
              state.form.password = "";
              state.form.confirmpassword = "";
              state.form.photourl = "";
              router.push({ path: "/signin" });
            }
            
          })
          .catch((error) => {
            if (error.code == 16) {
              state.authModalOpen = true;
              setTimeout(() => {
                state.authModalOpen = false;
                logOut();
              }, 5000);
            } else {
              state.backendErrors = JSON.parse(error);
            }
          });
      }
    };

    onMounted(() => {
      // window.location.href = config.authUrl + "/sign-up";
      setTimeout(() => {
              state.isLoading = false  
            }, 1000);
    });
    return {
      ...toRefs(state),
      createUserRegister,
      V1$,
      getBackendError,
      showPass,
      hidepass,
      toggleModal,
    };
  },
});
