

import { computed, defineComponent } from 'vue'
import store from '../../store';
import { useErrorHandler } from '../../views/composable/handleError';
export default defineComponent({
    name:'UnauthorizedModal',
    props:['code'],
    setup(props){
        const { logOut} = useErrorHandler();
        
        const authModalClose=()=>{
                store.dispatch('setErrorStatus', false);
                if(props.code == 16) {
                    logOut();
                }
            }
       
        return {
             authModalClose,
        }
    }
}
)
