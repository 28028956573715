
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs } from "vue";

import PageHeader from "../../../../components/list/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { toastrMsg } from "../../../../utils/toastr";
import { useI18n } from "vue-i18n";
import CategoryService from "../../../../services/category";
import { CategoryCreateRequest } from '../../../../types/category';
import { GoogleMap,Marker } from 'vue3-google-map';
import Loader from "../../../../components/comp/Loader.vue";

declare var google: any; // Declare the 'google' namespace

export default defineComponent({
  components: { PageHeader, Loader,GoogleMap, Marker},
  name: "ServiceAppointment",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const myDiv = ref<HTMLDivElement | null>(null); 
    const zoom = ref(10);
    const state = reactive({
      isLoading: true,
      isFileUpload: false,
      roleList: <any>[],
      viewData: {},
      productsData: {},
      centerS : <any>{},
      centerD : <any>{},
      categoryID: computed(() => route.params.id.toString()),
    });

    const removeCategory = () => {
      let id = route.params.id;
      let ID = id.toString();
      new CategoryService()
        .RemoveCategory(ID)
        .then((response: any) => {
          if (response !== undefined) {
            router.push({ path: "/admin/ecommerce/categorys" });
          }
        })
        .catch((error) => {});
    };

    const getCategoryView = () => {
      new CategoryService()
        .GetCategoryView(state.categoryID)
        .then((response: any) => {
          if (response !== undefined) {
            state.viewData = response.data;
            state.productsData = response.data.products;
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };

    const formattedText = computed(() => {
      if (myDiv.value) {
        const divElement = myDiv.value as HTMLElement;
        return divElement.innerText.replace(/- /g, "<br>- ");
      }
      return "";
    });
    onMounted(() => {
      getCategoryView();
      if (myDiv.value) {
        myDiv.value.innerHTML = formattedText.value;
      }
      if (
        localStorage.getItem("fromRoute") &&
        localStorage.getItem("fromRoute") == "edit"
      ) {
        toastrMsg("User edit successful.");
      }
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      myDiv,
      zoom,
      removeCategory,
      ...toRefs(state),
    };
  },
});
