

import { computed, defineComponent, reactive, toRefs} from 'vue'
import store from '../../store';
import { useErrorHandler } from '../../views/composable/handleError';

export default defineComponent({
    name:'UnauthorizedModal',
    setup(){
        
        const { logOut} = useErrorHandler();
        const state:any = reactive({
            storestate : computed(()=>store.state),
            errorMessage : computed(()=>state.storestate.common.error.message),
            errorTitle : computed(()=>state.storestate.common.error.title),
        })
            // const uniqueError = computed(()=>state.storestate.common.error);
        const ModalClose=()=>{
                store.dispatch('setUniqueErrorStatus', false);
            }
        return {
            ...toRefs(state) ,ModalClose,
        }
    }
}
)
