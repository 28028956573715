
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs } from "vue";

import PageHeader from "../../../components/list/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { toastrMsg } from "../../../utils/toastr";
import { useI18n } from "vue-i18n";
import CabService from "../../../services/cab";
import { CabUpdateRequest } from '../../../types/cab';
import { GoogleMap,Marker } from 'vue3-google-map';
import Loader from "../../../components/comp/Loader.vue";

declare var google: any; // Declare the 'google' namespace

let statusFormData : CabUpdateRequest={
        status:'',
        bookingId:'',
}

export default defineComponent({
  components: { PageHeader, Loader,GoogleMap, Marker},
  name: "ServiceAppointment",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const myDiv = ref<HTMLDivElement | null>(null); 
    const zoom = ref(10);
    const state = reactive({
      isLoading: true,
      isFileUpload: false,
      roleList: <any>[],
      statusFormData: {
        status: '',
        bookingId: '',
      } as CabUpdateRequest,
      viewData: {},
      userData: {},
      centerS : <any>{},
      centerD : <any>{},
      bookingID: computed(() => route.params.id.toString()),
    });

    const getCabBookingView = () => {
      new CabService()
        .GetCabBookingView(state.bookingID)
        .then((response: any) => {
          if (response !== undefined) {
            state.viewData = response.data;
            state.userData = response.data.user;
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };
    const changeStatus = (event: Event) => {
      const selectedValue = (event.target as HTMLSelectElement).value;
      state.statusFormData.status = selectedValue;
      state.statusFormData.bookingId = state.bookingID;

      new CabService().ChangeStatus(state.statusFormData)
        .then((response) => {
          if (response !== undefined) {
            getCabBookingView()
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };

    onMounted(() => {
      getCabBookingView();
      
      if (
        localStorage.getItem("fromRoute") &&
        localStorage.getItem("fromRoute") == "edit"
      ) {
        toastrMsg("User edit successful.");
      }
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      myDiv,
      zoom,
      changeStatus,
      ...toRefs(state),
    };
  },
});
