
import { defineComponent } from 'vue'

export default defineComponent({
    name:'TableTitleColumn',
    props: {
        name:{
           type: String,
           required: true
        },
        sortValue:{
           type: Number,
           required: false
        },
        header:{
           type: String,
        },
        sortoption:{
           type: Boolean,
           required: false
        },
        cls:{
           required: false
        }
    },
    emits:['sortIconClick'],
})
