

import { defineComponent, ref } from 'vue'
export default defineComponent({
    name:'ActionModal',
    props:['modalId'],
    emits:['toggledropdown'],
    setup(props, {emit}){
        const actionModalID = ref("")

        const toggleActionDropdown=()=>{
            if(!actionModalID.value || actionModalID.value != props.modalId){
                actionModalID.value=props.modalId 
            }
            else{
                actionModalID.value=""
            } 
             emit('toggledropdown', {modalId: props.modalId})
        }

        return{
            actionModalID,
            toggleActionDropdown
        }
    }
}
)
