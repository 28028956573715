import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-black80" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/profile",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.profile')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/preference",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.preference')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/security",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.security')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/kyc-verifications",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.kyc-verifications')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/referral",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.referral-link')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/referral/users",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.referral-users')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/refferal/earnings",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.referral-earnings')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/bank-accounts",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.my-banks')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/bank-accounts/create",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.add-new-bank')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        onClick: _ctx.changeProfileModalShow,
        to: "/activities",
        class: "flex items-center cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('message.recent-activities')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
        class: "flex cursor-pointer hover:bg-kakaPrimary text-black100 hover:dark:text-black100 hover:text-black100 dark:text-secondary py-2 px-4"
      }, _toDisplayString(_ctx.$t('message.logout')), 1)
    ])
  ]))
}