
import AddModal from "../../components/list/AddMoreModal.vue";
import PageHeader from "../../components/list/PageHeader.vue";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { LoginUserRequest } from "../../types/user";
import UserService from "../../services/user";
import SettingsService from "../../services/settings";
import { useVuelidate } from "@vuelidate/core";
import { useErrorHandler } from "../../views/composable/handleError";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
sameAs,
} from "@vuelidate/validators";
import {
  alphaSpacePattern,
  validatePhoneNumber,
} from "@/utils/form-validation";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import { GetSettingsRequest } from "../../types/settings";
import Loader from '../../components/comp/Loader.vue';
let signinForm: LoginUserRequest = {
  email: "",
  password: "",
};

export default defineComponent({
  components: { PageHeader, AddModal, VueHcaptcha,Loader },
  name: "signin",
  setup() {
    const { logOut } = useErrorHandler();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      isLoading:true,
      form: signinForm,
      errMsg:"",
      backendErrors: <any>{},
      authModalOpen: false,
      passwordData: {
        newPassEye: false,
        newPassNotEye: true,
        confirmPassEye: false,
        confirmPassNotEye: true,
      },
      isModalOpen: false,
    });
    const toggleModal = () => {
      state.isModalOpen = !state.isModalOpen;
    };

    const createRule = {
      form: {
        email: {
          required1: helpers.withMessage(
            `${t("message.email-is-required")}`,
            required
          ),
          required2: helpers.withMessage(
            `${t("message.insert-valid-email")}`,
            email
          ),
        },
        password: {
          required6: helpers.withMessage(`${t("message.required")}`, required),
          
        },
      },
    };

    const V1$ = useVuelidate(createRule, state);
    const getBackendError = (item: any) => {
      for (const key in state.backendErrors) {
        if (item == key) {
          return state.backendErrors[key];
        }
      }
    };
    const showPass = (e: any) => {
      if (e.parentElement.children[0].name == "Password") {
        e.parentElement.children[0].type = "text";
        state.passwordData.newPassEye = true;
        state.passwordData.newPassNotEye = false;
      }
      if (e.parentElement.children[0].name == "ConformPassword") {
        e.parentElement.children[0].type = "text";
        state.passwordData.confirmPassEye = true;
        state.passwordData.confirmPassNotEye = false;
      }
    };
    const hidepass = (e: any) => {
      if (e.parentElement.children[0].name == "Password") {
        e.parentElement.children[0].type = "password";
        state.passwordData.newPassEye = false;
        state.passwordData.newPassNotEye = true;
      }
      if (e.parentElement.children[0].name == "ConformPassword") {
        e.parentElement.children[0].type = "password";
        state.passwordData.confirmPassEye = false;
        state.passwordData.confirmPassNotEye = true;
      }
    };

    const loginUser = () => {
      V1$?.value.$touch();
      if (V1$?.value.$invalid) {
        return;
      } else {
        new UserService()
          .LoginUser(state.form).then((response: any) => {
            if (response.data.error !== false){
              state.errMsg = response.data.message
            }
            if (response.data !== undefined && response.data.error == false) {
              V1$.value.$reset();
              localStorage.setItem("userEmail", state.form.email);
              state.form.email = "";
              state.form.password = "";
              localStorage.setItem('token', response.data.data.token);
              router.push({ path: "/admin/dashboard" });
            }
          })
          .catch((error:any) => {
            if (error.code == 16) {
              state.authModalOpen = true;
              setTimeout(() => {
                state.authModalOpen = false;
                logOut();
              }, 5000);
            } else {
              state.errMsg = "Something wrong error"
            }
          });
      }
    };

    onMounted(() => {
      setTimeout(() => {
              state.isLoading = false  
            }, 1000);
    });
    return {
      ...toRefs(state),
      loginUser,
      V1$,
      getBackendError,
      showPass,
      hidepass,
      toggleModal,
    };
  },
});
