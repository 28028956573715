

import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted } from 'vue'

import ActionModalVue from '../../../components/list/ActionModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import { CreateLocation } from '../../../types/location';
import LocationService from "../../../services/location";
import ImageService from "../../../services/imageUpload";
import { ListImageRequest } from "../../../types/imageupload";
import { SaveImageRequest } from '../../../types/imageupload';
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, integer, maxLength, minLength, numeric, required, url } from '@vuelidate/validators'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Loader from '../../../components/comp/Loader.vue';
import config from '../../../../../kaka-web/src/config';
import { GoogleMap, Marker } from 'vue3-google-map';
import axios from 'axios';


let createlocation: CreateLocation= {
name:"",
reference:"",
latitude:"",
longitude:"",
enabled:"",
icon:"",
banner:"",
}
let listFilterData: ListImageRequest = {
  perPage: 20,
  page: 1,
};
let saveimage : SaveImageRequest={
        name:'',
        url:'',
}

export default defineComponent({
    components: {ActionModalVue, PageHeader, Loader,GoogleMap,Marker },
    name: 'car-create',
    setup(){
        const { t } = useI18n();
        const router = useRouter();
        const YOUR_GOOGLE_MAPS_API_KEY = config.googleApiKey;
        const center = ref({ lat: 24.72982116494692, lng: 46.68102461267188 });
         const zoom = ref(10);
        const fileInput = ref<HTMLInputElement | null>(null);
        const selectedFile = ref<File | null>(null);
        const state = reactive(
            {
                isLoading:true,
                form:createlocation,
                iform: {
                     file: null as File | null,
                },
                backerr: "",
                tableData: <any>[],
                backendErrors:<any>{},
                phoneValidate:false,
                authModalOpen:false,
                imageModel: false,
                bannerModel: false,
                addImage:false,
                saveimageform: saveimage,
            }
        );

        const createRule = {
            form:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                reference: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                latitude: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                longitude: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                enabled: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                icon: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        url: helpers.withMessage(t('message.Valid-url'), url)
                        },
                banner: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        url: helpers.withMessage(t('message.Valid-url'), url)
                        },
            },
        }
         const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }

        const createRule2 = {
            saveimageform:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V2$ = useVuelidate(createRule2, state)
        const getBackendError2=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
        

        const locationSubmit = () => {
            V1$?.value.$touch();
            if (V1$?.value.$invalid) {
                return;
            }else{
        new LocationService().CreateLocation(state.form).then((response:any)=>{
                if(response !== undefined && response.data !== undefined){
                    router.push({ path:"/admin/locations" })
                }
                if(response.error==true){
                    state.backerr = response.message
                }
            }).catch((error)=>{
                if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
    }
};
// file upload start
 const file = ref<File | null>(null);
    const fileSize = ref<number | null>(null);

    const onFileChange = (event: any) => {
      const selectedFile = event.target.files[0];
      file.value = selectedFile;
      fileSize.value = selectedFile.size;
      state.iform.file = selectedFile;
      const parts = selectedFile.name.split('.');
      const parts2 = parts[0];
      state.saveimageform.name = parts2
      submitImage()
    };
     const submitImage = () => {
    const formData = new FormData();
    if (state.iform.file) {
      formData.append("image", state.iform.file); 
      const url = config.authUrl + "/image/upload";
      const token = localStorage.getItem('token');
      axios.post(url, formData, {
        headers: {
          'Authorization':'Bearer '+token,
          "Content-Type": "multipart/form-data",
          "X-API-KEY": config.apiKey
        }
      })
      .then((response: any) => {
        state.saveimageform.url = response.data.data
        
      })
      .catch((error: any) => {
        console.error("Error:", error);
      });
    }
};
const submitForm = () => {
    V2$?.value.$touch();
     if (V2$?.value.$invalid) {
         return;
     }else{
          const url = config.authUrl + "/image";
            const token = localStorage.getItem('token');
            const params = {
                name: state.saveimageform.name,
                url: state.saveimageform.url,
              };
            axios.post(url, params, {
              headers: {
                'Authorization':'Bearer '+token,
                'Content-Type': 'application/json',
                "X-API-KEY": config.apiKey
              }
            })
            .then((response: any) => {
                ImageList();
              state.addImage = false;
            })
            .catch((error: any) => {
              console.error("Error:", error);
        });
    }
};
// file upload  end



function selectImage(){
    state.imageModel = true;
}

function closeIcon(){
    state.imageModel = false;
}
function selectBanner(){
    state.bannerModel = true;
}
function closeBanner(){
    state.bannerModel = false;
}
function iconUrl(url:any){
    state.form.icon = url
    state.imageModel = false;
}
function bannerUrl(url:any){
    state.form.banner = url
    state.bannerModel = false;
}
function addMore(){
    state.addImage = true;
}
function closeAddImage(){
    state.addImage = false;
}
const ImageList = ()=>{
            new ImageService().listOfImage().then((response:any)=>{
                if(response !== undefined){
                     state.tableData = response.data.data;
                    
                }
            }).catch((error)=>{
                  if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }

        const reset=()=>{
                        state.form.name= "";
                        state.form.reference= "";
                        state.form.latitude= "";
                        state.form.longitude= "";
                        state.form.enabled= "";
                        state.form.icon= "";
                        state.form.banner= "";
            V1$.value.$reset();
        }
        
   
    function handleMapClick(event:any) {
      const clickedLatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      state.form.latitude = clickedLatLng.lat.toString();
     state.form.longitude = clickedLatLng.lng.toString();
    }
       
        onMounted(()=>{
            ImageList();
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })
        onUnmounted(() => {
            reset();
        });


        return {
            ...toRefs(state),getBackendError, V1$, locationSubmit, fileInput,reset,
             center,zoom,selectImage,ImageList,iconUrl,selectBanner,bannerUrl,
             closeIcon, closeBanner, handleMapClick,addMore,closeAddImage,submitImage, submitForm, onFileChange, V2$, getBackendError2,
        }
    }
  
})
