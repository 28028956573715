
import { defineComponent, reactive, toRefs,} from 'vue'
import { useRouter } from 'vue-router';
import config from '../../config';
import { FetchData } from '../../utils/fetch-utils';
import { useStore } from 'vuex';


export default defineComponent({
    name:'ProfileOptions',

    setup(){
        const router = useRouter()
        const store = useStore();
        const changeProfileModalShow=()=>{
            store.dispatch("setProfileShowModalValue", false)
        }
        const logout=()=>{
            const url = config.authUrl+'/logout?accessToken=' + store.getters["auth/accessToken"];
            const method = "GET";
            FetchData(url, method, false, false)
            .then((result:any) => {
                if (result.error) {
                } else {
                    if(result.data){
                        localStorage.removeItem('vuex');
                        const isAuthenticated = store.getters["auth/isValidTokenExpired"];
                        store.dispatch("auth/resetAuthState")
                        router.push({ path: "/signin" });
                    }
                }
            }); 
            
        }

        return {
            logout,changeProfileModalShow
        }
    }
})
