import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-x-auto customScrollX border-b dark:border-b-0" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "dark:bg-black90 bg-blue10 bg-opacity-10 dark:opacity-100" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  colspan: "21",
  class: "text-center py-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.$t('message.this-is-the-default')), 1)
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "body", {}, () => [
          _createElementVNode("tr", _hoisted_4, [
            _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t('message.no-data-found')), 1)
          ])
        ])
      ])
    ])
  ]))
}