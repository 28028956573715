
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs } from "vue";

import PageHeader from "../../../components/list/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { toastrMsg } from "../../../utils/toastr";
import { useI18n } from "vue-i18n";
import PackageService from "../../../services/package";
import { GoogleMap,Marker } from 'vue3-google-map';
import Loader from "../../../components/comp/Loader.vue";

declare var google: any; // Declare the 'google' namespace

export default defineComponent({
  components: { PageHeader, Loader,GoogleMap, Marker},
  name: "ServiceAppointment",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const myDiv = ref<HTMLDivElement | null>(null); 
    const zoom = ref(10);
    const state = reactive({
      isLoading: true,
      isFileUpload: false,
      roleList: <any>[],
      viewData: {},
      centerS : <any>{},
      centerD : <any>{},
      packageID: computed(() => route.params.id.toString()),
    });

    const removePackage = () => {
      let id = route.params.id;
      let ID = id.toString();
      new PackageService()
        .RemovePackage(ID)
        .then((response: any) => {
          if (response !== undefined) {
            router.push({ path: "/admin/packages" });
          }
        })
        .catch((error) => {});
    };

    const getPackageView = () => {
      new PackageService()
        .GetPackageView(state.packageID)
        .then((response: any) => {
          if (response !== undefined) {
            state.viewData = response.data;
            const lat = parseFloat(response.data.source.latitude);
            const lng = parseFloat(response.data.source.longitude);
            state.centerS = ref({lat:lat, lng:lng});
            const latd = parseFloat(response.data.destination.latitude);
            const lngd = parseFloat(response.data.destination.longitude);
            state.centerD = ref({lat:latd, lng:lngd});
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };

    const formattedText = computed(() => {
      if (myDiv.value) {
        const divElement = myDiv.value as HTMLElement;
        return divElement.innerText.replace(/- /g, "<br>- ");
      }
      return "";
    });
    onMounted(() => {
      getPackageView();
      if (myDiv.value) {
        myDiv.value.innerHTML = formattedText.value;
      }
      if (
        localStorage.getItem("fromRoute") &&
        localStorage.getItem("fromRoute") == "edit"
      ) {
        toastrMsg("User edit successful.");
      }
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      myDiv,
      zoom,
      removePackage,
      ...toRefs(state),
    };
  },
});
