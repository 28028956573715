

import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted } from 'vue'

import ActionModalVue from '../../../components/list/ActionModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import { UpdateQurbani } from '../../../types/qurbani';
import QurbaniService from "../../../services/qurbani";
import ImageService from "../../../services/imageUpload";
import { ListImageRequest } from "../../../types/imageupload";
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, integer, maxLength, minLength, numeric, required } from '@vuelidate/validators'
import { useRouter,useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Loader from '../../../components/comp/Loader.vue';
import config from '../../../../../kaka-web/src/config';
import { GoogleMap, Marker } from 'vue3-google-map';


let updatequrbani: UpdateQurbani= {
id :"",    
name:"",
description:"",
enabled:"",
icon:"",
banner:"",
}
let listFilterData: ListImageRequest = {
  perPage: 20,
  page: 1,
};

export default defineComponent({
    components: {ActionModalVue, PageHeader, Loader,GoogleMap,Marker },
    name: 'car-create',
    setup(){
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute()
        const YOUR_GOOGLE_MAPS_API_KEY = config.googleApiKey;
        const center = ref({ lat: 37.7749, lng: -122.4194 });
         const zoom = ref(10);
        const fileInput = ref<HTMLInputElement | null>(null);
        const selectedFile = ref<File | null>(null);
        const state = reactive(
            {
                isLoading:true,
                form:updatequrbani,
                iform: {
                    files: [] as File[]
                },
                backerr: "",
                tableData: <any>[],
                backendErrors:<any>{},
                phoneValidate:false,
                authModalOpen:false,
                imageModel: false,
                bannerModel: false
            }
        );

        const createRule = {
            form:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                description: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                enabled: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                icon: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                banner: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
        

        const qurbaniSubmit = () => {
            V1$?.value.$touch();
            if (V1$?.value.$invalid) {
                return;
            }else{
            let id = route.params.id;
            let ID =id.toString()
            state.form.id = ID
        new QurbaniService().UpdateQurbani(state.form).then((response:any)=>{
                if(response !== undefined && response.data !== undefined){
                    router.push({ path:"/admin/qurbanis" })
                }
                if(response.error==true){
                    state.backerr = response.message
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
    }
};



function selectImage(){
    state.imageModel = true;
}
function closeIcon(){
    state.imageModel = false;
}
function selectBanner(){
    state.bannerModel = true;
}
function closeBanner(){
    state.bannerModel = false;
}
function iconUrl(url:any){
    state.form.icon = url
    state.imageModel = false;
}
function bannerUrl(url:any){
    state.form.banner = url
    state.bannerModel = false;
}
const ImageList = ()=>{
            new ImageService().listOfImage().then((response:any)=>{
                if(response !== undefined){
                     state.tableData = response.data.data;
                    
                }
            }).catch((error)=>{});
        }

        const reset=()=>{
                        state.form.name= "";
                        state.form.description= "";
                        state.form.enabled= "";
                        state.form.icon= "";
                        state.form.banner= "";
            V1$.value.$reset();
        }
        
   

     const getQurbaniView=()=>{
             let id = route.params.id;
            let ID =id.toString()
            new QurbaniService().GetQurbaniView(ID).then((response:any)=>{
                if(response !== undefined && response.data !== undefined){  
                    state.form.name= response.data.name
                    state.form.description= response.data.description
                    state.form.enabled= response.data.enabled
                    state.form.icon= response.data.icon
                    state.form.banner= response.data.banner
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });

        }
       
        onMounted(()=>{
            getQurbaniView();
            ImageList();
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })
        onUnmounted(() => {
            reset();
        });


        return {
            ...toRefs(state),getBackendError, V1$, qurbaniSubmit, fileInput,reset,
             center,zoom,selectImage,ImageList,iconUrl,selectBanner,bannerUrl,
             closeIcon, closeBanner
        }
    }
  
})
