
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs } from "vue";

import PageHeader from "../../../components/list/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { toastrMsg } from "../../../utils/toastr";
import { useI18n } from "vue-i18n";
import HijamaService from "../../../services/hijama";
import ImageService from "../../../services/imageUpload";
import { SaveImageRequest } from '../../../types/imageupload';
import { DeleteHijamaImage, CreateHijamaImage } from '../../../types/hijama';
import { GoogleMap,Marker } from 'vue3-google-map';
import Loader from "../../../components/comp/Loader.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import config from "@/config";
import axios from "axios";


let delHijamaImage : DeleteHijamaImage={
        hijamaId:'',
        imageId:'',
}
let createHijamaImage : CreateHijamaImage={
        url:'',
        title:'',
        description:'',
        enabled:'',
}

let saveimage : SaveImageRequest={
        name:'',
        url:'',
}

export default defineComponent({
  components: { PageHeader, Loader,GoogleMap, Marker},
  name: "ServiceAppointment",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const myDiv = ref<HTMLDivElement | null>(null); 
    const zoom = ref(10);
    const state = reactive({
      isLoading: true,
      isFileUpload: false,
      delHijama:delHijamaImage,
      hijamaimageform:createHijamaImage,
      tableData: <any>[],
      roleList: <any>[],
      backendErrors:<any>{},
      iform: {
                     file: null as File | null,
                },
      viewData: {},
      imageModel: false,
      addImage:false,
      addQurbaniImage:false,
      saveimageform: saveimage,
      centerS : <any>{},
      centerD : <any>{},
      productID: computed(() => route.params.id.toString()),
    });
    const createRule2 = {
            saveimageform:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V2$ = useVuelidate(createRule2, state)
        const getBackendError2=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }

    const createRule3 = {
            hijamaimageform:{
                url: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                title: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                description: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                enabled: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
            },
        }
         const V3$ = useVuelidate(createRule3, state)
        const getBackendError3=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }

    const removeHijama = () => {
      let id = route.params.id;
      let ID = id.toString();
      new HijamaService()
        .RemoveHijama(ID)
        .then((response: any) => {
          if (response !== undefined) {
            router.push({ path: "/admin/hijamas" });
          }
        })
        .catch((error) => {});
    };

    const getHijamaView = () => {
      new HijamaService()
        .GetHijamaView(state.productID)
        .then((response: any) => {
          if (response !== undefined) {
            state.viewData = response.data;
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };
    
    const removeHijamaImage=(imageID:string)=>{
            let id = route.params.id;
            let ID =id.toString()
            state.delHijama.hijamaId = ID
            state.delHijama.imageId = imageID
            new HijamaService().RemoveHijamaImage(state.delHijama).then((response:any)=>{
                if(response !== undefined){
                   getHijamaView();
                }
            }).catch((error)=>{});
        }
       const ImageList = ()=>{
            new ImageService().listOfImage().then((response:any)=>{
                if(response !== undefined){
                     state.tableData = response.data.data;
                }
            }).catch((error)=>{
               if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }

        // file upload start
        const file = ref<File | null>(null);
        const fileSize = ref<number | null>(null);
        const onFileChange = (event: any) => {
          const selectedFile = event.target.files[0];
          file.value = selectedFile;
          fileSize.value = selectedFile.size;
          state.iform.file = selectedFile;
          const parts = selectedFile.name.split('.');
          const parts2 = parts[0];
          state.saveimageform.name = parts2
          submitImage()
        };

        const submitImage = () => {
        const formData = new FormData();
        if (state.iform.file) {
          formData.append("image", state.iform.file); 
          const url = config.authUrl + "/image/upload";
          const token = localStorage.getItem('token');
          axios.post(url, formData, {
            headers: {
              'Authorization':'Bearer '+token,
              "Content-Type": "multipart/form-data",
              "X-API-KEY": config.apiKey
            }
          })
          .then((response: any) => {
            state.saveimageform.url = response.data.data
            
          })
          .catch((error: any) => {
            console.error("Error:", error);
          });
        }
    };
        const submitForm = () => {
        V2$?.value.$touch();
        if (V2$?.value.$invalid) {
            return;
        }else{
                const url = config.authUrl + "/image";
                const token = localStorage.getItem('token');
                const params = {
                    name: state.saveimageform.name,
                    url: state.saveimageform.url,
                  };
                axios.post(url, params, {
                  headers: {
                    'Authorization':'Bearer '+token,
                    'Content-Type': 'application/json',
                    "X-API-KEY": config.apiKey
                  }
                })
                .then((response: any) => {
                    ImageList();
                  state.addImage = false;
                })
                .catch((error: any) => {
                  if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }
    };
         const submitImageForm = () => {
        V3$?.value.$touch();
        if (V3$?.value.$invalid) {
            return;
        }else{
              let id = route.params.id;
               let ID =id.toString()
              const url = config.authUrl+"/hijama/"+ID+"/image";
                const token = localStorage.getItem('token');
                const params = {
                    url: state.hijamaimageform.url,
                    title: state.hijamaimageform.title,
                    description: state.hijamaimageform.description,
                    enabled: state.hijamaimageform.enabled,
                  };
                axios.post(url, params, {
                  headers: {
                    'Authorization':'Bearer '+token,
                    'Content-Type': 'application/json',
                    "X-API-KEY": config.apiKey
                  }
                })
                .then((response: any) => {
                  getHijamaView();
                  state.imageModel = false;
                  state.addImage = false;
                  state.addQurbaniImage = false;
                  getHijamaView();
                })
                .catch((error: any) => {
                  if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }
    };
      function selectImage(){
          state.imageModel = true
      }

      function iconID(url:any){
          state.hijamaimageform.url = url
          state.addQurbaniImage = true;
      }
      function closeIcon(){
          state.imageModel = false;
      }
            function addMore(){
          state.addImage = true;
      }
      function closeAddImage(){
          state.addImage = false;
      }
      function closeQurbaniImage(){
          state.addQurbaniImage = false;
      }

    onMounted(() => {
      getHijamaView();
      ImageList();
      if (
        localStorage.getItem("fromRoute") &&
        localStorage.getItem("fromRoute") == "edit"
      ) {
        toastrMsg("User edit successful.");
      }
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      myDiv,
      zoom,
      removeHijama,
      iconID,
      removeHijamaImage,
      selectImage,
      closeIcon,
      addMore,
      closeAddImage,
      closeQurbaniImage,
      onFileChange,
      V2$,
      V3$,
      getBackendError2,
      getBackendError3,
      submitImageForm,
      submitForm,
      ...toRefs(state),
    };
  },
});
