
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs } from "vue";

import PageHeader from "../../../components/list/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { toastrMsg } from "../../../utils/toastr";
import { useI18n } from "vue-i18n";
import PackageService from "../../../services/package";
import { BookingStatusUpdateRequest } from '../../../types/package';
import LocationService from '../../../services/location';
import Loader from "../../../components/comp/Loader.vue";

declare var google: any; // Declare the 'google' namespace

export default defineComponent({
  components: { PageHeader, Loader},
  name: "ServiceAppointment",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const myDiv = ref<HTMLDivElement | null>(null); 
    const state = reactive({
      isLoading: true,
      isFileUpload: false,
      roleList: <any>[],
      statusFormData: {
        status: '',
        bookingId: '',
      } as BookingStatusUpdateRequest,
      viewData: {},
      paymentData: {},
      packageData: {},
      userData: {},
      offerData: {},
      sourceData: {},
      destinationData: {},
      sourceID:"",
      destinationID:"",
      centerS : <any>{},
      centerD : <any>{},
      packageBookingID: computed(() => route.params.id.toString()),
      bookingID: computed(() => route.params.id.toString()),

    });

    const getPackageBookingView = () => {
      new PackageService()
        .GetPackageBookingView(state.packageBookingID)
        .then((response: any) => {
          if (response !== undefined) {
            state.viewData = response.data;
            state.paymentData = response.data.payment;
            state.packageData = response.data.package;
            state.userData = response.data.user;
            state.offerData = response.data.offer;
            state.sourceID = response.data.package.source
            state.destinationID = response.data.package.destination
            getSource(state.sourceID);
            getDestination(state.destinationID);
          }
        })
        .catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };

    const getSource = (sourceID:string) => {
      new LocationService().GetLocationView(sourceID)
        .then((response: any) => {
          if (response !== undefined) {
            state.sourceData = response.data;
          }
        }).catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };

    const getDestination = (destinationID:string) => {
      new LocationService().GetLocationView(destinationID)
        .then((response: any) => {
          if (response !== undefined) {
            state.destinationData = response.data;

          }
        }).catch((error) => {
           if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    };
     const changeStatus = (event: Event) => {
      const selectedValue = (event.target as HTMLSelectElement).value;
      state.statusFormData.status = selectedValue;
      state.statusFormData.bookingId = state.bookingID;

      new PackageService().ChangeStatus(state.statusFormData)
        .then((response) => {
          if (response !== undefined) {
            getPackageBookingView()
          }
        })
        .catch((error) => {
          console.error("Error changing status:", error);
        });
    };
    function isValidUrl(url:string) {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    };


    onMounted(() => {
      getPackageBookingView();
      if (
        localStorage.getItem("fromRoute") &&
        localStorage.getItem("fromRoute") == "edit"
      ) {
        toastrMsg("User edit successful.");
      }
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      myDiv,
      getPackageBookingView,
      getSource,
      getDestination,
      changeStatus,
      isValidUrl,
      ...toRefs(state),
    };
  },
});
