
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import axios from "axios";
import config from "../../../config";
import { SaveImageRequest } from '../../../types/imageupload';
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";


let saveimage : SaveImageRequest={
        name:'',
        url:'',
}

export default defineComponent({
  name: "upload-image",
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      isLoading: true,
      form: {
        file: null as File | null,
      },
      saveimageform: saveimage,
      errMsg: "",
      backendErrors: {} as Record<string, any>,
    });
        const createRule = {
            saveimageform:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }

    const file = ref<File | null>(null);
    const fileSize = ref<number | null>(null);

    const onFileChange = (event: any) => {
      const selectedFile = event.target.files[0];
      file.value = selectedFile;
      fileSize.value = selectedFile.size;
      state.form.file = selectedFile;
      const parts = selectedFile.name.split('.');
      const parts2 = parts[0];
      state.saveimageform.name = parts2
      submitImage()
    };

  const submitImage = () => {
    const formData = new FormData();
    if (state.form.file) {
      formData.append("image", state.form.file); 
      const url = config.authUrl + "/image/upload";
      const token = localStorage.getItem('token');
      axios.post(url, formData, {
        headers: {
          'Authorization':'Bearer '+token,
          "Content-Type": "multipart/form-data",
          "X-API-KEY": config.apiKey
        }
      })
      .then((response: any) => {
        state.saveimageform.url = response.data.data
        
      })
      .catch((error: any) => {
        if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
      });
    }
};

  const submitForm = () => {
    V1$?.value.$touch();
     if (V1$?.value.$invalid) {
         return;
     }else{
          const url = config.authUrl + "/image";
            const token = localStorage.getItem('token');
            const params = {
                name: state.saveimageform.name,
                url: state.saveimageform.url,
              };
            axios.post(url, params, {
              headers: {
                'Authorization':'Bearer '+token,
                'Content-Type': 'application/json',
                "X-API-KEY": config.apiKey
              }
            })
            .then((response: any) => {
              router.push({ path:"/admin/images/list" })
            })
            .catch((error: any) => {
               if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
        });
    }
};

    onMounted(() => {
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });

    return {
      ...toRefs(state),
      V1$,
      submitImage,
      submitForm,
      onFileChange,
      getBackendError,
      file,
      fileSize,
    };
  },
});
