
import { defineComponent, onUpdated, ref } from 'vue'
export default defineComponent({
    name:"TablePagginationLimit",
    props : ['totalPage','limit',"rowPerPage"],
    components: {},
    setup(props, { emit }){
        const start_index = ref(1);
        const paggination_button_value = ref(1);
        const next=()=>{
                start_index.value = start_index.value+1;

                emit('nextEvent', start_index.value)
            };
        const prev=()=>{
            if(start_index.value > 0) start_index.value = start_index.value -1;

            emit('prevEvent', start_index.value)
        };
        const pageButton = (page:number) =>{
            start_index.value = page;

            emit('pageButtonValue', start_index.value)
        }
        onUpdated(()=>{
        })
        return{
            next,
            prev,
            pageButton,
            paggination_button_value,
            start_index,
        };
    },

})
