
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name:"TablePaggination",
    props: {
        prevCursor: {
           type: String,
           default:"", 
        },
        nextCursor: {
           type: String,
           default:"", 
        },
        total: {
           type: Number,
           default:0, 
        },
        perPage: {
           type: Number,
           default:0, 
        },
    },
    emits:[ "next", "prev", "updateLimit"]
})
