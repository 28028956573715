

import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted } from 'vue'

import ActionModalVue from '../../../components/list/ActionModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import { InvitedUser } from '../../../types/user';
import UserService from "../../../services/user";
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { alphaSpacePattern,UidValidation, validatePhoneNumber } from '../../../utils/form-validation';
import Loader from '../../../components/comp/Loader.vue';


let inviteuser: InvitedUser= {
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    roleid: "",
    referrerid: "",
    address: "",
}

export default defineComponent({
    components: {ActionModalVue, PageHeader, Loader },
    name: 'InviteUser',
    setup(){
        const { t } = useI18n();
        const router = useRouter();
        const state = reactive(
            {
                isLoading:true,
                roleList:<any>[],
                form:inviteuser,
                backendErrors:<any>{},
                phoneValidate:false,
                authModalOpen:false
            }
        );

        const createRule = {
            form:{
                roleid: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        required2: helpers.withMessage(`${t('message.valid-id')}`, UidValidation),
                        },
                firstname: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        required2: helpers.withMessage(`${t('message.this-length')}`, maxLength(20)),
                        required3: helpers.withMessage(`${t('message.only-text')}`, alphaSpacePattern),
                        },
                lastname: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        required2: helpers.withMessage(`${t('message.this-length')}`, maxLength(20)),
                        required3: helpers.withMessage(`${t('message.only-text')}`, alphaSpacePattern),
                        },
                email: {
                    required1: helpers.withMessage(`${t('message.required')}`, required),
                    required2: helpers.withMessage(`${t('message.insert-valid-email')}`, email),
                },
                phonenumber: {
                    required1: helpers.withMessage(`${t('message.required')}`, required),
                    required2: helpers.withMessage(`${t('message.insert-valid-phone-number')}`, maxLength(20)),
                    required3: helpers.withMessage(`${t('message.insert-valid-phone-number')}`, minLength(5)),
                    required4: helpers.withMessage(`${t('message.insert-valid-phone-number')}`, validatePhoneNumber),
                }, 
            },
        }
        const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }

        const inviteusersubmit = ()=>{
            V1$?.value.$touch();
            if (V1$?.value.$invalid) {
                return;
            } 
            else {
                new UserService().inviteUser(state.form).then((response:any)=>{
                    if(response !== undefined && response.Data!==undefined){
                        state.form.firstname="";
                        state.form.lastname="";
                        state.form.email="";
                        state.form.phonenumber="";
                        state.form.address="";
                        state.form.roleid="";
                        V1$.value.$reset();
                        localStorage.setItem('fromRoute', 'create');
                        router.push({ path:"/admin/users" })
                    }
                }).catch((error)=>{ 
                    state.backendErrors = JSON.parse(error);
                });
                
            }
        }
        const reset=()=>{
            state.form.firstname = "";
            state.form.lastname = "";
            state.form.email = "";
            state.form.phonenumber = "";
            state.form.address = "";
            state.form.roleid = "";
            V1$.value.$reset();
        }
        

       
        onMounted(()=>{
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })
        onUnmounted(() => {
            reset();
        });


        return {
            ...toRefs(state), V1$, inviteusersubmit, getBackendError,reset
        }
    }
  
})
