
import { defineComponent,reactive, ref,onMounted,toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GoogleMap,Marker } from 'vue3-google-map';
import PageHeader from '../../../components/list/PageHeader.vue';
import Loader from '../../../components/comp/Loader.vue';
import QurbaniService from "../../../services/qurbani";
import { CreateQurbaniImage,DeleteQurbaniImage,DeleteQurbaniAnimalType,CreateQurbaniAnimalType,UpdateQurbaniAnimalType } from '../../../types/qurbani';
import { SaveImageRequest } from '../../../types/imageupload';
import ImageService from "../../../services/imageUpload";
import config from '../../../../../kaka-web/src/config';
import router from '../../../../../kaka-web/src/router';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';


let createqurbaniimage : CreateQurbaniImage={
        qurbaniid:'',
        url:'',
        title:'',
        description:'',
        enabled:'',
}

let saveimage : SaveImageRequest={
        name:'',
        url:'',
}

let delQurbaniImage : DeleteQurbaniImage={
        qurbaniid:'',
        imageId:'',
}
let createQurbaniAnimalType : CreateQurbaniAnimalType={
        qurbaniId:'',
        type:'',
        base_price:'',
        price:'',
}
let updateQurbaniAnimalType : UpdateQurbaniAnimalType={
        qurbaniId:'',
        typeID:'',
        type:'',
        base_price:'',
        price:'',
}
let delQurbaniType : DeleteQurbaniAnimalType={
        qurbaniid:'',
        typeId:'',
}


export default defineComponent({
  components: {PageHeader,Loader, Marker },
  setup() {
    const { t } = useI18n();
    const route = useRoute()
    const fileInput = ref<HTMLInputElement | null>(null);
    const selectedFile = ref<File | null>(null);
    const state = reactive(
            {
                isLoading:true,
                tableData: <any>[],
                viewData:{},
                imageid:"",
                qurbaniimageform:createqurbaniimage,
                animalTypeForm:createQurbaniAnimalType,
                updateTypeForm:updateQurbaniAnimalType,
                delqurreq:delQurbaniImage,
                deltypreq:delQurbaniType,
                backendErrors:<any>{},
                  iform: {
                     file: null as File | null,
                },
                imageModel: false,
                addImage:false,
                addQurbaniImage:false,
                animalType:false,
                updateAnimalType:false,
                saveimageform: saveimage,
            }
        );
         const createRule = {
            animalTypeForm:{
                type: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                base_price: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
                price: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
 
     const createRule2 = {
            saveimageform:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        },
            },
        }
         const V2$ = useVuelidate(createRule2, state)
        const getBackendError2=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
     const createRule3 = {
            qurbaniimageform:{
                url: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                title: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                description: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                enabled: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
            },
        }
         const V3$ = useVuelidate(createRule3, state)
        const getBackendError3=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
     const createRule4 = {
            updateTypeForm:{
                type: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                base_price: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
                price: {
                      required1: helpers.withMessage(`${t('message.required')}`, required),
                      },
            },
        }
        const V4$ = useVuelidate(createRule4, state)
        const getBackendError4=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
  // file upload start
    const file = ref<File | null>(null);
    const fileSize = ref<number | null>(null);
    const onFileChange = (event: any) => {
      const selectedFile = event.target.files[0];
      file.value = selectedFile;
      fileSize.value = selectedFile.size;
      state.iform.file = selectedFile;
      const parts = selectedFile.name.split('.');
      const parts2 = parts[0];
      state.saveimageform.name = parts2
      submitImage()
    };
      const submitImage = () => {
        const formData = new FormData();
        if (state.iform.file) {
          formData.append("image", state.iform.file); 
          const url = config.authUrl + "/image/upload";
          const token = localStorage.getItem('token');
          axios.post(url, formData, {
            headers: {
              'Authorization':'Bearer '+token,
              "Content-Type": "multipart/form-data",
              "X-API-KEY": config.apiKey
            }
          })
          .then((response: any) => {
            state.saveimageform.url = response.data.data
            
          })
          .catch((error: any) => {
            console.error("Error:", error);
          });
        }
    };
    const submitForm = () => {
        V2$?.value.$touch();
        if (V2$?.value.$invalid) {
            return;
        }else{
                const url = config.authUrl + "/image";
                const token = localStorage.getItem('token');
                const params = {
                    name: state.saveimageform.name,
                    url: state.saveimageform.url,
                  };
                axios.post(url, params, {
                  headers: {
                    'Authorization':'Bearer '+token,
                    'Content-Type': 'application/json',
                    "X-API-KEY": config.apiKey
                  }
                })
                .then((response: any) => {
                    ImageList();
                  state.addImage = false;
                })
                .catch((error: any) => {
                  console.error("Error:", error);
            });
        }
    };
    const submitImageForm = () => {
        V3$?.value.$touch();
        if (V3$?.value.$invalid) {
            return;
        }else{
              let id = route.params.id;
               let ID =id.toString()
              const url = config.authUrl+"/qurbani/"+ID+"/image";
                const token = localStorage.getItem('token');
                const params = {
                    url: state.qurbaniimageform.url,
                    title: state.qurbaniimageform.title,
                    description: state.qurbaniimageform.description,
                    enabled: state.qurbaniimageform.enabled,
                  };
                axios.post(url, params, {
                  headers: {
                    'Authorization':'Bearer '+token,
                    'Content-Type': 'application/json',
                    "X-API-KEY": config.apiKey
                  }
                })
                .then((response: any) => {
                  getQurbaniView();
                  state.imageModel = false;
                  state.addImage = false;
                  state.addQurbaniImage = false;

                  getQurbaniView();
                })
                .catch((error: any) => {
                  console.error("Error:", error);
            });
        }
    };
    const removeQurbaniImage=(imageID:string)=>{
            let id = route.params.id;
            let ID =id.toString()
            state.delqurreq.qurbaniid = ID
            state.delqurreq.imageId = imageID
            new QurbaniService().RemoveQurbaniImage(state.delqurreq).then((response:any)=>{
                if(response !== undefined){
                   getQurbaniView();
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }
    
        const ImageList = ()=>{
            new ImageService().listOfImage().then((response:any)=>{
                if(response !== undefined){
                     state.tableData = response.data.data;
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }
        const getQurbaniView=()=>{
             let id = route.params.id;
            let ID =id.toString()
            new QurbaniService().GetQurbaniView(ID).then((response:any)=>{
                if(response !== undefined){
                    state.viewData = response.data
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });

        }
        const getQurbaniTypeView=(id:string)=>{
            new QurbaniService().GetQurbaniTypeView(id).then((response:any)=>{
                if(response !== undefined){
                    state.animalTypeForm.type= response.data.type;
                    state.animalTypeForm.base_price= response.data.base_price;
                    state.animalTypeForm.price= response.data.price;
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });

        }
        const removeQurbani=()=>{
            let id = route.params.id;
            let ID =id.toString()
            new QurbaniService().RemoveQurbani(ID).then((response:any)=>{
                if(response !== undefined){
                    router.push({ path:"/admin/qurbanis" })
                }
            }).catch((error)=>{});
        }
        const removeQurbaniType=(typeID:string)=>{
            let id = route.params.id;
            let ID =id.toString()
            state.deltypreq.qurbaniid = ID
            state.deltypreq.typeId = typeID
            new QurbaniService().RemoveQurbaniType(state.deltypreq).then((response:any)=>{
                if(response !== undefined){
                   getQurbaniView();
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }
        const animalTypeSubmit = () => {
            V1$?.value.$touch();
            if (V1$?.value.$invalid) {
                return;
            }else{
            let id = route.params.id;
            let ID =id.toString();
            state.animalTypeForm.qurbaniId = ID;
        new QurbaniService().CreateAnimalType(state.animalTypeForm).then((response:any)=>{
                if(response !== undefined && response.data !== undefined){
                   getQurbaniView()
                    state.animalType = false;
                    state.animalTypeForm.type= "";
                    state.animalTypeForm.base_price= "";
                    state.animalTypeForm.price= "";
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
    }
};
const updateQurbaniTypeSubmit = () => {
            V4$?.value.$touch();
            if (V4$?.value.$invalid) {
                return;
            }else{
            let id = route.params.id;
            let ID =id.toString()
            state.updateTypeForm.qurbaniId = ID
        new QurbaniService().updateQurbaniType(state.updateTypeForm).then((response:any)=>{
                if(response !== undefined && response.data !== undefined){
                    getQurbaniView();
                    state.updateAnimalType = false;
                     state.updateTypeForm.type= "";
                    state.updateTypeForm.base_price= "";
                    state.updateTypeForm.price= "";
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
    }
};
        const reset=()=>{
                        state.animalTypeForm.type= "";
                        state.animalTypeForm.base_price= "";
                        state.animalTypeForm.price= "";
            V1$.value.$reset();
        }
        function iconID(url:any){
          state.qurbaniimageform.url = url
          state.addQurbaniImage = true;
      }
        function selectImage(){
          state.imageModel = true;
      }

      function closeIcon(){
          state.imageModel = false;
      }
            function addMore(){
          state.addImage = true;
      }
      function closeAddImage(){
          state.addImage = false;
      }
      function closeQurbaniImage(){
          state.addQurbaniImage = false;
      }
      function addQurbaniType(){
          state.animalType = true;
      }
      function closeQurbaniType(){
          state.animalType = false;
      }
      function updateQurbaniTypes(id:string){
          state.updateAnimalType = true;
          getQurbaniTypeView(id)
          state.updateTypeForm.typeID = id
      }
      function closeupdateQurbaniTypes(){
          state.updateAnimalType = false;
      }
    onMounted(()=>{
        getQurbaniView();
        ImageList();
            setTimeout(() => {
              state.isLoading = false
            }, 1000);
        })

    return {
          getQurbaniView,
          removeQurbani,
          onFileChange,
          submitImage,
          addMore,
          closeAddImage,
          V1$,
          getBackendError,
          V2$,
          getBackendError2,
          submitForm,
          ImageList,
          selectImage,
          closeIcon,
          iconID,
          closeQurbaniImage,
          V3$,
          V4$,
          getBackendError3,
          getBackendError4,
          submitImageForm,
          removeQurbaniImage,
          removeQurbaniType,
          addQurbaniType,
          closeQurbaniType,
          animalTypeSubmit,
          reset,
          updateQurbaniTypeSubmit,
          updateQurbaniTypes,
          closeupdateQurbaniTypes,
          getQurbaniTypeView,
          ...toRefs(state),
           };
  }
});
