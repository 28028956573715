export const Languages = [
    {
        name: 'English',
        message: 'english'
    },
    {
        name: 'Portuguese',
        message: 'portuguese'
    }
]
