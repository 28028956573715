
import { defineComponent,reactive, ref,onMounted,toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GoogleMap,Marker } from 'vue3-google-map';
import PageHeader from '../../../components/list/PageHeader.vue';
import Loader from '../../../components/comp/Loader.vue';
import ImageService from "../../../services/imageUpload";
import LocationService from '../../../services/location';
import config from '../../../../../kaka-web/src/config';
import router from '../../../../../kaka-web/src/router';





export default defineComponent({
  components: {PageHeader,Loader, GoogleMap, Marker },
  setup() {
    const route = useRoute()
    const YOUR_GOOGLE_MAPS_API_KEY = config.googleApiKey;
    const zoom = ref(10);
    const state = reactive(
            {
                isLoading:true,
                 viewData:{},
                 center : <any>{},
            }
        );

        const getLocationView=()=>{
             let id = route.params.id;
            let ID =id.toString()
            new LocationService().GetLocationView(ID).then((response:any)=>{
                if(response !== undefined){
                    state.viewData = response.data
                    const lat = parseFloat(response.data.latitude);
                    const lng = parseFloat(response.data.longitude);
                    state.center = ref({lat:lat, lng:lng});
                }
            }).catch((error)=>{
               if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });

        }
        const removeLocation=()=>{
            let id = route.params.id;
            let ID =id.toString()
            new LocationService().RemoveLocation(ID).then((response:any)=>{
                if(response !== undefined){
                    router.push({ path:"/admin/locations" })
                }
            }).catch((error)=>{});
        }

    onMounted(()=>{
        getLocationView();

            setTimeout(() => {
              state.isLoading = false
            }, 1000);
        })

    return {
         YOUR_GOOGLE_MAPS_API_KEY,
          getLocationView,
          removeLocation,
          zoom,
          ...toRefs(state),
           };
  }
});
