
import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted, onBeforeUnmount } from 'vue'
import actionDropdown from '../../composable/actionDropdown';
import ActionModalVue from '../../../components/list/ActionModal.vue';
import AddModal from '../../../components/list/AddMoreModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import TableCompo from '../../../components/list/TableCompo.vue';
import TablePaggination from '../../../components/list/TablePaggination.vue';
import TableTitleColumn from '../../../components/list/TableTitleColumn.vue';
import TableDataComp from '../../../components/list/TableDataComp.vue';
import TableDataCompImg from '../../../components/list/TableDataCompImg.vue';
import FilterModal from '../../../components/list/FilterModal.vue';
import { useStore } from 'vuex';
import QurbaniService from "../../../services/qurbani";
import { QurbaniListRequest } from '../../../types/qurbani';
import 'vue3-toastify/dist/index.css';
import { toastrMsg } from "../../../utils/toastr";
import { routeGroupCheck } from "../../../utils/route-utils"
import Loader from '../../../components/comp/Loader.vue';
import TablePaginationLimit from '../../../components/list/TablePaginationLimit.vue';
import router from '@/router';

let listFilterData : QurbaniListRequest={
        perPage:10,
        page:1,
}

export default defineComponent({
    components: { FilterModal, ActionModalVue, PageHeader, AddModal, TableCompo, TableTitleColumn, TablePaggination, TableDataComp, Loader,TablePaginationLimit,TableDataCompImg},
   
    name: 'location',
    setup(){
       const { actionModalID, actionButton, positionEditDrop, toggleActionDropdown } = actionDropdown();
        const store = useStore();
         const showModal = ref(false);
        const state = reactive(
            {
                isLoading:true,
                isFilterOpen:false,
                tableData:<any>[],
                filterForm:listFilterData,
                sortIconData : 0,
                activeHeader : '',
                carimage:<any>[],
                prevElm : '',
                userStatus: false,
                ListLength:0,
                roleList:{},
                totalPage:0,
                totalRowsPerPage:0,
                limit:0,

            }
        );


        const setTableData = ()=>{
            new QurbaniService().listOfQurbani(state.filterForm).then((response:any)=>{
                if(response !== undefined){
                    state.tableData = response.data.data
                    state.totalPage = response.data.totalPage
                    state.totalRowsPerPage = response.data.data.length
                    state.limit = response.data.perPage
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
        }

        onBeforeUnmount(()=>{
            let routeData = routeGroupCheck();
            if (routeData.reset == "TRUE") {
            }
            setTableData
        })
          const toggleModal = () => {
            console.log(showModal)
            showModal.value = !showModal.value;
    };
     function pagginationButtonClick(p:number){
            state.filterForm.page = p
            setTableData()
        }

        onMounted(()=>{
            setTableData();
            positionEditDrop();

            if(localStorage.getItem('fromRoute') && localStorage.getItem('fromRoute')=='create'){
                toastrMsg('User created successfully.');
            }
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })

        return {
            ...toRefs(state),setTableData,positionEditDrop,
            actionModalID, toggleActionDropdown, toggleModal, actionButton,showModal,pagginationButtonClick
            
        }

    }
  
})
