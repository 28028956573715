

import { defineComponent, ref, reactive, computed, Ref, onMounted, toRefs, onUnmounted } from 'vue'

import ActionModalVue from '../../../components/list/ActionModal.vue';
import PageHeader from '../../../components/list/PageHeader.vue';
import PackageService from "../../../services/package";
import { PackageUpdateRequest } from '../../../types/package';
import LocationService from "../../../services/location";
import { LocationListRequest } from '../../../types/location';
import ImageService from "../../../services/imageUpload";
import { ListImageRequest } from "../../../types/imageupload";
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, integer, maxLength, minLength, numeric, required, url } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Loader from '../../../components/comp/Loader.vue';
import config from '../../../config';


let createpackage: PackageUpdateRequest= {
id : "",    
name: "",
source: "",
destination: "",
description: "",
duration: "",
days: "",
nights: "",
persons: "",
base_price: "",
discount_price: "",
price: "",
booking_amount: "",
offer_applicable: "",
icon: "",
banner: "",
thing_will_love: "",
}
let listFilterData : LocationListRequest={
        perPage:100000,
        page:1,
}

export default defineComponent({
    components: {ActionModalVue, PageHeader, Loader },
    name: 'car-create',
    setup(){
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const YOUR_GOOGLE_MAPS_API_KEY = config.googleApiKey;
        const center = ref({ lat: 37.7749, lng: -122.4194 });
         const zoom = ref(10);
        const fileInput = ref<HTMLInputElement | null>(null);
        const selectedFile = ref<File | null>(null);
        const state = reactive(
            {
                isLoading:true,
                form:createpackage,
                 filterForm:listFilterData,
                tableData: <any>[],
                locationData: <any>[],
                backendErrors:<any>{},
                phoneValidate:false,
                authModalOpen:false,
                imageModel: false,
                bannerModel: false
            }
        );

        const createRule = {
            form:{
                name: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                    },
                source: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                destination: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                description: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                    },
                duration: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                    },
                days: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                nights: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                persons: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                base_price: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                discount_price: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                price: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                booking_amount: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        numeric: helpers.withMessage(t('message.number-is-required'), numeric),
                    },
                offer_applicable: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                    },
                icon: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        url: helpers.withMessage(t('message.Valid-url'), url)
                    },
                banner: {
                        required1: helpers.withMessage(`${t('message.required')}`, required),
                        url: helpers.withMessage(t('message.Valid-url'), url)

                    },
                
            },
        }
         const V1$ = useVuelidate(createRule, state)
        const getBackendError=(item:any)=>{
            for (const key in state.backendErrors) {
                if(item==key){
                    return state.backendErrors[key];
                }
            }
        }
        

        const packagesSubmit = () => {
            V1$?.value.$touch();
            if (V1$?.value.$invalid) {
                return;
            }else{
            let id = route.params.id;
            let ID =id.toString()
            state.form.id = ID
        new PackageService().UpdatePackage(state.form).then((response:any)=>{
                if(response !== undefined && response.data!== undefined){
                    router.push({ path:"/admin/packages" })
                }
            }).catch((error)=>{
                 if(error.response != undefined){
                    if(error.response.status === 401){
                       localStorage.removeItem('token');
                       router.push({ path: "/signin" });
                   }
                }
            });
    }
};



function selectImage(){
    state.imageModel = true;
}
function closeIcon(){
    state.imageModel = false;
}
function selectBanner(){
    state.bannerModel = true;
}
function closeBanner(){
    state.bannerModel = false;
}
function iconUrl(url:any){
    state.form.icon = url
    state.imageModel = false;
}
function bannerUrl(url:any){
    state.form.banner = url
    state.bannerModel = false;
}
const ImageList = ()=>{
    new ImageService().listOfImage().then((response:any)=>{
        if(response !== undefined){
             state.tableData = response.data.data;
            
        }
    }).catch((error)=>{});
}

 const ListLocations = ()=>{
            new LocationService().listOfLocation(state.filterForm).then((response:any)=>{
                if(response !== undefined){
                    state.locationData = response.data.data
                }
            }).catch((error)=>{});
        }

const getPackageView = () => {
        let id = route.params.id;
        let ID =id.toString()
      new PackageService().GetPackageView(ID).then((response: any) => {
          if (response !== undefined && response.data!== undefined) {
            state.form.name =response.data.name
            state.form.source = response.data.source
            state.form.destination = response.data.destination
            state.form.description = response.data.description
            state.form.duration = response.data.duration
            state.form.days = response.data.days
            state.form.nights = response.data.nights
            state.form.persons = response.data.persons
            state.form.base_price = response.data.base_price
            state.form.discount_price = response.data.discount_price
            state.form.price = response.data.price
            state.form.booking_amount = response.data.booking_amount
            state.form.offer_applicable = response.data.offer_applicable
            state.form.icon = response.data.icon
            state.form.banner = response.data.banner
            state.form.thing_will_love = response.data.thing_will_love
          }
        })
        .catch((error) => {});
    };

        const reset=()=>{
            state.form.name = "",
            state.form.source = "",
            state.form.destination = "",
            state.form.description = "",
            state.form.duration = "",
            state.form.days = "",
            state.form.nights = "",
            state.form.persons = "",
            state.form.base_price = "",
            state.form.discount_price = "",
            state.form.price = "",
            state.form.booking_amount = "",
            state.form.offer_applicable = "",
            state.form.icon = "",
            state.form.banner = "",
            state.form.thing_will_love = "",
            V1$.value.$reset();
        }
        
       
        onMounted(()=>{
            getPackageView();
            ListLocations();
            ImageList();
            setTimeout(() => {
              state.isLoading = false  
            }, 1000);
        })
        onUnmounted(() => {
            reset();
        });


        return {
            ...toRefs(state),getBackendError, V1$, packagesSubmit, fileInput,reset,
             center,zoom,selectImage,ImageList,iconUrl,selectBanner,bannerUrl,ListLocations,
             closeIcon, closeBanner
        }
    }
  
})
